import { Box } from '@chakra-ui/react'
import { RouteComponentProps, useLocation } from '@reach/router'

import React, { lazy, Suspense, useEffect, useMemo, useState } from 'react'
import ContentBox from '~components/shared/content-box'
import LocationsBox from './locations-box'
import { debounce } from 'lodash'
import { navigate } from 'gatsby-link'
import querystring from 'query-string'
// import MapComponent from './map-component'
// const MapComponent =
//   typeof window !== 'undefined' ? require('./map-component') : null
const MapComponent = lazy(() => import('./map-component'))

interface Props extends RouteComponentProps {
  id?: string
  data?: any
  isLoading?: boolean
  totalUsers?: any
}

const Map: React.FC<Props> = ({ data, isLoading = false, totalUsers }) => {
  const { search, ...propsLoc } = useLocation()
  console.log(search, propsLoc)
  const [locations, setLocations] = useState(true)
  const [loading, setLoading] = useState(true)
  const [totalHits, setTotalHits] = useState(0)
  const [searchTime, setSearchTime] = useState(0)

  const [hitsPerPage, setHitsPerPage] = useState(10)

  // const isLoading = false
  // const query=[{}]
  const { state, city } = querystring.parse(search)

  useEffect(() => {
    console.log(search, state, 'queryy')
  }, [search])
  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target
    if (value === '') navigate(`/events`)
    else navigate(`/events?${querystring.stringify({ query: value })}`)
    setHitsPerPage(10)
  }

  const debouncedSearchText = useMemo(
    () => debounce(handleSearchTextChange, 200),
    []
  )

  const searchEvents = async () => {
    setLoading(true)
    // const { hits, nbHits, processingTimeMS } = await eventsIndex.search(
    //   query || '',
    //   {
    //     hitsPerPage,
    //     facetFilters: ['isActive: true'],
    //   }
    // )
    // @ts-ignore
    // setLocations(hits)
    // setLoading(false)
    // setTotalHits(nbHits)
    // setSearchTime(processingTimeMS / 100)
  }
  // useEffect(() => {
  //   searchEvents()
  // }, [query, hitsPerPage])
  console.log(data)
  const optionsLocations = useMemo(() => {
    let init = []
    if (data) {
      init = data.map((ele, i) => ({
        id: i,
        locationName: ele.location,
        users: ele.user_count,
        location: ele.location,
        type: ele.level,
      }))
    }
    return init
  }, [data])

  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])
  return (
    <ContentBox
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 5,
        height: 500,
        position: 'relative',
        zIndex: 0,
      }}
    >
      <Box position="relative" zIndex={2} width="100%">
        {isClient && (
          <Suspense fallback={<div>Cargando mapa...</div>}>
            {typeof window != 'undefined' && MapComponent != null && (
              <MapComponent />
            )}
          </Suspense>
        )}
      </Box>

      <LocationsBox
        isLoading={isLoading}
        locations={optionsLocations}
        totalUsers={totalUsers}
      />
    </ContentBox>
  )
}

export default Map
