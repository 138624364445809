import React, { useEffect } from 'react'
import { Router, useLocation } from '@reach/router'
import StatisticsDetailsPage from '~components/pages/statistics/index'
import { navigate } from 'gatsby'
import querystring from 'query-string'

const StadisticsPage: React.FC = () => {
  const { search, ...propsLoc } = useLocation()
  const { country } = querystring.parse(search)

  const countryDefault = () => {
    // alert(`Saved: ${selectedCountry}, ${selectedState || 'No state selected'}`)
    if (!country) {
      const newLocations = {
        country: 'United States',
      }

      navigate(`/statistics?${querystring.stringify(newLocations)}`)
    }
  }
  useEffect(() => {
    if (!country) {
      countryDefault()
    }
  }, [country])
  return (
    <Router basepath="/statistics">
      <StatisticsDetailsPage path="/" />
    </Router>
  )
}

export default StadisticsPage
