import React from 'react'
import { Box, Text, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import { Link, navigate } from 'gatsby'
import querystring from 'query-string'
import { useLocation } from '@reach/router'

export interface ILocationElement {
  id: string
  // orders: number
  // eventName: string
  // eventSlug: string
  users: number
  locationName: string
  location: string | any
  type: string
}

interface Props {
  elements: ILocationElement[]
}

const LocationsTable: React.FC<Props> = ({ elements }) => {
  const { search, ...propsLoc } = useLocation()

  const { state, city, ...propsQ } = querystring.parse(search)

  // console.log(state, city, 'querys-table')
  const handleLocation = (option?: any) => {
    console.log(option)
    if (!option) return
    let query = '/statistics?'
    // if() ${querystring.stringify({ query: value })}
    // navigate(`/statistics?${option?.type}=${option?.location}`)
    navigate(
      `/statistics?${querystring.stringify({
        state,
        city,
        ...propsQ,
        [option?.type]: option?.location,
      })}`
    )
  }
  console.log(elements, 'lements')
  return (
    <Box overflowY="auto">
      <Table variant="simple">
        {/* <Thead>
          <Tr>
            <Th>Event</Th>
            <Th isNumeric>Orders</Th>
          </Tr>
        </Thead> */}
        <Tbody>
          {elements.map((event, i) => (
            <Tr
              key={event.id}
              fontSize="sm"
              bgColor={
                state == event.location || city == event.location
                  ? 'brand.50'
                  : ''
              } // || city == event.id
              _hover={{
                bgColor:
                  event.type == 'state' || event.type == 'city'
                    ? 'brand.50'
                    : 'tranparent',
                // cursor: event.type == 'state' ? 'pointer' : '',
                cursor: 'pointer',
              }}
              onClick={() => handleLocation(event)}
            >
              <Td width="fit-content">{i + 1}</Td>
              <Td>
                {/* <Link to={`/events/${event.locationName}`}> */}
                <Text
                  fontWeight="semibold"
                  // _hover={{ textDecor: 'underline' }}
                >
                  {event.locationName}
                </Text>
                <Text
                  // fontWeight="semibold"
                  // _hover={{ textDecor: 'underline' }}
                  color="gray"
                >
                  {event.users} users
                </Text>
                {/* </Link> */}
              </Td>
              {/* <Td isNumeric>{event.orders}</Td> */}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  )
}

export default LocationsTable
