import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from '@chakra-ui/react'
import { IoIosArrowDown } from 'react-icons/io'
import ListLocationFilter from './list-location-filter'
import { useAuth } from '~context/auth'
import axios from 'axios'
import querystring from 'query-string'
import { useLocation } from '@reach/router'

interface LocationsFilter {
  setValues?: any
  valueInit?: any
  values?: any
}

const LocationFilter: React.FC<LocationsFilter> = ({
  setValues,
  valueInit,
  values,
}) => {
  const { authData } = useAuth()
  const { search, ...propsLoc } = useLocation()

  const { state, city, country } = querystring.parse(search)

  const [open, setOpen] = useState<boolean>(false)

  const [searchCountry, setSearchCountry] = useState('')
  const [searchState, setSearchState] = useState('')
  const [searchCity, setSearchCity] = useState('')

  const [countries, setCountries] = useState<any>([])
  const [states, setStates] = useState<any>([])
  const [cities, setCities] = useState<any>([])

  const [isLoadingCountries, setIsLoadingCountries] = useState(false)
  const [isLoadingStates, setIsLoadingStates] = useState(false)
  const [isLoadingCities, setIsLoadingCities] = useState(false)

  // const states = [
  //   'California',
  //   'Miami',
  //   'Florida',
  //   'Texas',
  //   'Illinois',
  //   'Pennsylvania',
  // ]

  const getLocations = async (country?: any, state?: any) => {
    let url = `${process.env.GATSBY_STRAPI_API_URL}/statistics/geolocations?level=country`
    if (!country && !state) {
      setIsLoadingCountries(true)
    }
    if (country) {
      url = `${process.env.GATSBY_STRAPI_API_URL}/statistics/geolocations?level=state&country=${country}`
      setIsLoadingStates(true)
    }
    if (country && state) {
      url = `${process.env.GATSBY_STRAPI_API_URL}/statistics/geolocations?level=city&country=${country}&state=${state}`
      setIsLoadingCities(true)
    }
    try {
      const result = await axios.get(url, {
        headers: {
          'x-fp-access': 'grvxiEvnSVq2_Iy1Gr-uBA',
          Authorization: `Bearer ${authData?.jwt}`,
        },
      })
      // console.log('resultcountries', result)
      // setTicketDetail(result.data)
      if (country && !state) {
        setStates(result.data)
        setIsLoadingStates(false)
      } else if (country && state) {
        setCities(result.data)
        setIsLoadingCities(false)
      } else {
        setCountries(result.data)
        setIsLoadingCountries(false)
      }
    } catch (error) {
      console.error('Error fetching Countries:', error)
      setIsLoadingStates(false)
      setIsLoadingCities(false)
      setIsLoadingCountries(false)
    }
  }
  useEffect(() => {
    getLocations()
    if (values?.country) {
      getLocations(values?.country)
    }
  }, [])

  useEffect(() => {
    if (values?.country) {
      getLocations(values?.country)
    }
  }, [values?.country])

  useEffect(() => {
    if (values?.country && values?.state) {
      getLocations(values?.country, values?.state)
    }
  }, [values?.state])

  const filteredCountries = countries.filter((country?: any) =>
    country.toLowerCase().includes(searchCountry.toLowerCase())
  )

  const filteredStates = states.filter((state?: any) =>
    state.toLowerCase().includes(searchState.toLowerCase())
  )

  const filteredCities = cities.filter((city?: any) =>
    city.toLowerCase().includes(searchCity.toLowerCase())
  )

  const handleClearAll = () => {
    setSearchCity('')
    setSearchState('')
    setValues((prev?: any) => ({
      ...prev,
      country: 'United States',
      state: '',
      city: '',
    }))
  }

  const handleChangeCountry = (value?: any) => {
    // if (value !== selectedCountry) {
    //   setSearchCity('')
    //   setSearchState('')
    //   setSelectedCity(null)
    //   setSelectedState(null)
    // }
    setValues((prev?: any) => ({
      ...prev,
      country: value,
      state: value !== values?.country ? null : prev.state,
      city: value !== values?.country ? null : prev.city,
    }))
  }

  return (
    <Box>
      {/* <InputGroup mb={4} variant="filled">
        {selectedCountry} <IoIosArrowDown />
      </InputGroup> */}
      <Popover
        placement="bottom-start"
        isOpen={open}
        onClose={() => setOpen(false)}
      >
        <PopoverTrigger>
          <Button
            // tabIndex="0"
            aria-label="Some box"
            // p={5}
            // w="120px"
            bg="gray.300"
            // children="Click"
            onClick={() => setOpen(!open)}
            _focus={{
              boxShadow: 'none',
            }}
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
              backgroundColor: 'white',
              '&:focus-visible': {
                border: 'none',
              },
              width: 'fit-content',
              paddingY: 2,
              paddingX: 4,
              borderRadius: 8,
              fontWeight: 'normal',
            }}
          >
            {valueInit || country} <IoIosArrowDown />
            {/* {open ? <IoIosArrowUp /> : <IoIosArrowDown />} */}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          w="fit-content"
          _focus={{
            boxShadow: 'none',
          }}
        >
          <PopoverBody>
            <Box p={4}>
              <Flex justifyContent="space-between" alignItems="center" mb={3}>
                <Box sx={{ fontSize: 16, fontWeight: 500 }}>
                  Select your location
                </Box>
                {/* Actions */}
                <Flex gap={1} fontSize={14} alignItems="center">
                  <Button
                    variant="ghost"
                    onClick={handleClearAll}
                    sx={{
                      fontSize: 'inherit',
                      fontWeight: 'light',
                    }}
                  >
                    Clear all
                  </Button>
                </Flex>
              </Flex>
              <Flex gap={4} direction="row">
                {/* Country Search and Selection */}

                <ListLocationFilter
                  value={values?.country}
                  // setValue={setSelectedCountry}
                  handleChange={handleChangeCountry}
                  listOptions={filteredCountries}
                  searchValue={searchCountry}
                  setSearchValue={setSearchCountry}
                  permitValueEmpty
                  placeholder="country"
                  isLoading={isLoadingCountries}
                />

                {/* State Search and Selection */}
                <ListLocationFilter
                  value={values?.state}
                  setValue={(v?: any) =>
                    setValues((prev?: any) => ({ ...prev, state: v }))
                  }
                  listOptions={filteredStates}
                  searchValue={searchState}
                  setSearchValue={setSearchState}
                  placeholder="state"
                  isLoading={isLoadingStates}
                />
                {values?.state && (
                  <ListLocationFilter
                    value={values?.city}
                    setValue={(v?: any) =>
                      setValues((prev?: any) => ({ ...prev, city: v }))
                    }
                    listOptions={filteredCities}
                    searchValue={searchCity}
                    setSearchValue={setSearchCity}
                    placeholder="city"
                    isLoading={isLoadingCities}
                  />
                )}
              </Flex>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}

export default LocationFilter
