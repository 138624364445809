import { Flex } from '@chakra-ui/react'
import { RouteComponentProps, useLocation } from '@reach/router'

import React, { useEffect, useMemo, useState } from 'react'
import Heading from '~components/shared/heading'
import { debounce } from 'lodash'
import { navigate } from 'gatsby-link'
import querystring from 'query-string'
import Filters from './filters/filters'
import Indicators from './indicators'
import axios from 'axios'
import { useAuth } from '~context/auth'
import Map from './map/map'
import Members from './members/members'

interface Props extends RouteComponentProps {
  id?: string
}

const StatisticsDetailsPage: React.FC<Props> = () => {
  const { search, ...propsLoc } = useLocation()
  const { authData } = useAuth()

  console.log(search, propsLoc)
  const [dataMap, setDataMap] = useState()
  const [data, setData] = useState()
  const [totalUsers, setTotalUsers] = useState()
  const [newUsers, setNewUsers] = useState()

  const [locations, setLocations] = useState(true)
  const [loading, setLoading] = useState(true)
  const [totalHits, setTotalHits] = useState(0)
  const [searchTime, setSearchTime] = useState(0)

  const [hitsPerPage, setHitsPerPage] = useState(10)

  const isLoading = false
  // const query=[{}]
  const { state, city, country, subscription, membership, badges } =
    querystring.parse(search)

  useEffect(() => {
    console.log(search, state, 'queryy')
  }, [search])
  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target
    if (value === '') navigate(`/events`)
    else navigate(`/events?${querystring.stringify({ query: value })}`)
    setHitsPerPage(10)
  }

  const debouncedSearchText = useMemo(
    () => debounce(handleSearchTextChange, 200),
    []
  )

  const searchEvents = async () => {
    setLoading(true)
    // const { hits, nbHits, processingTimeMS } = await eventsIndex.search(
    //   query || '',
    //   {
    //     hitsPerPage,
    //     facetFilters: ['isActive: true'],
    //   }
    // )
    // @ts-ignore
    // setLocations(hits)
    // setLoading(false)
    // setTotalHits(nbHits)
    // setSearchTime(processingTimeMS / 100)
  }
  // useEffect(() => {
  //   searchEvents()
  // }, [query, hitsPerPage])

  // /statistics/user-details?country=United States

  const fetchData = async (
    countr?: any,
    stat?: any,
    cit?: any,
    subscri?: any,
    membersh?: any,
    badge?: any
  ) => {
    let url = `${process.env.GATSBY_STRAPI_API_URL}/statistics/user-details`

    if (countr) {
      url = `${url}?country=${countr}`
    }
    if (stat) {
      url = `${url}&state=${stat}`
    }
    if (cit) {
      url = `${url}&city=${cit}`
    }
    if (subscri) {
      url = `${url}&subscription=${subscri}`
    }
    if (membersh) {
      url = `${url}&membership=${membersh}`
    }
    if (badge) {
      url = `${url}&badges=${badge}`
    }

    try {
      const result = await axios.get(url, {
        headers: {
          'x-fp-access': 'grvxiEvnSVq2_Iy1Gr-uBA',
          Authorization: `Bearer ${authData?.jwt}`,
        },
      })
      console.log('results data', result)
      const { data } = result
      setData(data.users)
      setDataMap(data?.quantity_by_geolocation)
      setTotalUsers(data?.users?.length)
      setNewUsers(data?.total_metrics?.total_recent_users)
      // setOptionsSubscription(result.data)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }
  useEffect(() => {
    if (country) {
      fetchData(country, state, city, subscription, membership, badges)
    }
  }, [state, city, country, subscription, membership, badges])

  const countryDefault = () => {
    // alert(`Saved: ${selectedCountry}, ${selectedState || 'No state selected'}`)
    if (!country) {
      const newLocations = {
        country: 'United States',
      }

      navigate(`/statistics?${querystring.stringify(newLocations)}`)
    }
  }
  useEffect(() => {
    countryDefault()
  }, [])
  return (
    <Flex gap={4} direction="column">
      <Heading>Statistics </Heading>
      <Filters />
      <Indicators totalUsers={totalUsers} totalNewUsers={newUsers} />
      <Map data={dataMap} totalUsers={totalUsers} />
      <Members data={data} />
    </Flex>
  )
}

export default StatisticsDetailsPage
