import { Box, Flex, Stack, Wrap } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import ContentBox from '~components/shared/content-box'

interface Props {
  id?: string
  totalUsers?: any
  totalNewUsers?: any
}
const Indicators: React.FC<Props> = ({ totalUsers, totalNewUsers }) => {
  const data = useMemo(
    () => [
      { value: totalUsers, label: 'Total users', percent: '50%' },
      { value: totalNewUsers || 'N/A', label: 'New users', percent: '50%' },
      { value: 1200, label: 'Tickets buy', percent: '50%' },
      // { value: 85, label: 'Active Events', percent: '50%' },
    ],
    [totalNewUsers, totalUsers]
  )
  return (
    <Wrap spacing={4}>
      {data.map((ele, i) => (
        <ContentBox
          key={ele?.label || i}
          textAlign="center"
          //   width="100%"
          //   maxWidth="calc( 25% - 16px ) "
          sx={{
            // width: '100%',
            // minWidth: {
            //   xs: 'calc( 50% - 16px )',
            //   md: 'calc( 25% - 16px )',
            // },
            minWidth: {
              //   xs: '100% ',
              sm: 'calc( 100% / 3  - 16px ) ',
            },
          }}
        >
          <Box fontSize={32} fontWeight={700}>
            {ele.value}
          </Box>
          <Box fontSize={18} fontWeight={500}>
            {ele.label}{' '}
          </Box>
          {/* <Box fontSize={12} fontWeight={400} color="#22C770">
            {ele.percent} more than the last 2 weeks{' '}
          </Box> */}
        </ContentBox>
      ))}
    </Wrap>
  )
}

export default Indicators
