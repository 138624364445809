import React from 'react'
import { Progress } from '@chakra-ui/progress'

import ContentBox from '~components/shared/content-box'
import ContentBoxTitle from '~components/shared/content-box-title'
import LocationsTable, { ILocationElement } from './locations-table'
import { useLocation } from '@reach/router'
import querystring from 'query-string'
import { IconButton } from '@chakra-ui/react'
import { HiArrowNarrowLeft } from 'react-icons/hi'
import { navigate } from 'gatsby'

// import TopEventsTable, { ITopEvent } from './top-events-table'

interface Props {
  isLoading: boolean
  locations: ILocationElement[]
  onClickOption?: any
  totalUsers?: any
}

const LocationsBox: React.FC<Props> = ({
  isLoading,
  locations,
  totalUsers,
}) => {
  const { search, ...propsLoc } = useLocation()

  const { ...propsQ } = querystring.parse(search)

  const { state, city } = querystring.parse(search)

  const handleBack = () => {
    const query = {
      ...propsQ,
    }
    if (city) {
      query.city = ''
    } else if (state) {
      query.state = ''
    }

    navigate(`/statistics?${querystring.stringify(query)}`)
  }

  return (
    <ContentBox
      p={0}
      width="fit-content"
      minWidth={250}
      maxHeight="100%"
      overflow="auto"
      // overflowY="auto"
    >
      {/* <ContentBoxTitle pl={6}>Top Events</ContentBoxTitle> */}
      <div
        style={{
          display: 'flex',
          gap: 6,
          justifyContent: 'space-between',
          paddingLeft: 24,
          paddingRight: 24,
        }}
      >
        {/* <ContentBoxTitle>USA</ContentBoxTitle> */}
        {(state || city) && (
          <IconButton aria-label="icon-arrow-left" onClick={handleBack}>
            <HiArrowNarrowLeft />
          </IconButton>
        )}
        <ContentBoxTitle>{totalUsers} users</ContentBoxTitle>
      </div>
      <LocationsTable elements={locations} />
      {isLoading && <Progress h="2px" colorScheme="brand" isIndeterminate />}
    </ContentBox>
  )
}

export default LocationsBox
