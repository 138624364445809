import React, { useState } from 'react'

import {
  Box,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Spinner,
} from '@chakra-ui/react'
import { HiOutlineSearch } from 'react-icons/hi'

interface PropsListLocation {
  value?: any
  setValue?: any
  listOptions?: any[]
  searchValue?: any
  setSearchValue?: any
  permitValueEmpty?: boolean
  handleChange?: (value?: any) => void
  placeholder?: string
  isLoading?: boolean
}

const ListLocationFilter: React.FC<PropsListLocation> = ({
  value,
  setValue,
  listOptions = [],
  searchValue,
  setSearchValue,
  permitValueEmpty = false,
  handleChange,
  placeholder = 'country',
  isLoading = false,
}) => {
  return (
    <Box width={200}>
      <Flex mb={3} alignItems="center">
        {/* <HiOutlineSearch color="gray.500" mr={2} /> */}
        <InputGroup sx={{ display: 'flex', alignItems: 'center' }}>
          <Input
            placeholder={`Search by ${placeholder}`}
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            paddingY={1}
            fontSize={14}
            height="fit-content"
          />
          <InputRightElement height="100%">
            <HiOutlineSearch size={15} />
          </InputRightElement>
        </InputGroup>
      </Flex>
      <List spacing={2} maxH="150px" overflowY="auto" colorScheme="red">
        {listOptions.length == 0 && isLoading && (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <Spinner
              sx={{
                color: 'brand.400',
              }}
            />
          </Box>
        )}

        {listOptions?.length > 0 &&
          listOptions.map((country?: any) => (
            <ListItem key={country}>
              <Checkbox
                // ="brand.400"

                sx={{
                  '& .chakra-checkbox__label': {
                    fontSize: 14,
                  },
                  '& .chakra-checkbox__control': {
                    borderRadius: '50%',
                    boxShadow: 'none',
                  },
                }}
                //   colorScheme="red"
                //   defaultChecked
                //   icon={<CustomIcon />}
                isChecked={value === country}
                onChange={() => {
                  if (handleChange) {
                    handleChange(country)
                    return
                  }
                  if (value == country && permitValueEmpty == false) {
                    setValue(null)
                  } else {
                    setValue(country)
                  }
                }}
                _checked={{
                  '& .chakra-checkbox__control': {
                    background: 'brand.400',
                    border: 'none',
                    boxShadow: 'none',
                    '& :hover': {
                      background: 'transparent !important',
                      height: '100%',
                    },
                    '& .data-hover': {
                      background: 'brand.400',
                    },
                  },
                  '& .data-hover': {
                    background: 'brand.400',
                  },
                }}
                _hover={{
                  '& .chakra-checkbox__control': {
                    // background: 'brand.400',
                    '& .data-checked': {
                      background: 'brand.400',

                      border: 'none',
                      boxShadow: 'none',
                    },
                  },
                }}
                _focus={{
                  '& .chakra-checkbox__control': {
                    border: 'none',
                    boxShadow: 'none',
                  },
                  boxShadow: 'none',
                }}
              >
                {country}
              </Checkbox>
            </ListItem>
          ))}
      </List>
    </Box>
  )
}
export default ListLocationFilter
