import React, { useMemo } from 'react'
import ContentBox from '~components/shared/content-box'
import ContentBoxTitle from '~components/shared/content-box-title'
import MembersTable from './members-table'

interface Props {
  debouncedSearchText?: any
  data?: any
  //   options?: IOptionSearchDropdown[]
}

const Members: React.FC<Props> = ({
  debouncedSearchText,
  data,
  //   options,
}) => {
  console.log(data, 'table')
  const firstElements = useMemo(() => {
    return data?.slice(0, 10) || []
  }, [data])
  return (
    <ContentBox>
      <ContentBoxTitle pl={6}>Members</ContentBoxTitle>
      <MembersTable data={firstElements} />
    </ContentBox>
  )
}

export default Members
