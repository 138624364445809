import { RouteComponentProps, useLocation } from '@reach/router'
import React, { useEffect, useState } from 'react'
import ContentBox from '~components/shared/content-box'

import LocationFilter from './location-filter'
import { Button, Flex, Select, SelectField } from '@chakra-ui/react'
import { useAuth } from '~context/auth'
import axios from 'axios'
import querystring from 'query-string'
import { navigate } from 'gatsby'

interface Props {
  debouncedSearchText?: any
  //   options?: IOptionSearchDropdown[]
}
const Filters: React.FC<RouteComponentProps<Props>> = ({
  debouncedSearchText,
  //   options,
}) => {
  const { authData } = useAuth()
  const [optionsSubscription, setOptionsSubscription] = useState([])
  const [optionsBadges, setOptionsBadges] = useState([])
  const optionsMembership = [
    'Basic',
    'Gold',
    'Platinum',
    'Founder',
    'Influencer',
  ]
  const { search, ...propsLoc } = useLocation()

  const { state, city, country, subscription, membership, badges } =
    querystring.parse(search)

  const [values, setValues] = useState<any>({
    country: country || 'United States',
    state: state || '',
    city: city || '',
    subscription: subscription || '',
    membership: membership || '',
    badges: badges || '',
  })

  const fetchSubscription = async () => {
    let url = `${process.env.GATSBY_STRAPI_API_URL}/products?isActive=true&type=subscription`
    try {
      const result = await axios.get(url, {
        headers: {
          'x-fp-access': 'grvxiEvnSVq2_Iy1Gr-uBA',
          Authorization: `Bearer ${authData?.jwt}`,
        },
      })
      console.log('results', result)
      setOptionsSubscription(result.data)
    } catch (error) {
      console.error('Error fetching subscription:', error)
    }
  }
  const fetchBadges = async () => {
    let url = `${process.env.GATSBY_STRAPI_API_URL}/badges`
    try {
      const result = await axios.get(url, {
        headers: {
          'x-fp-access': 'grvxiEvnSVq2_Iy1Gr-uBA',
          Authorization: `Bearer ${authData?.jwt}`,
        },
      })
      console.log('results badge', result)
      setOptionsBadges(result.data)
    } catch (error) {
      console.error('Error fetching subscription:', error)
    }
  }

  const handleSearch = () => {
    // alert(`Saved: ${selectedCountry}, ${selectedState || 'No state selected'}`)
    const newLocations: any = {
      country: values.country || country,
    }
    if (values.state) {
      newLocations.state = values.state
    }
    if (values.city) {
      newLocations.city = values.city
    }
    if (values.subscription) {
      newLocations.subscription = values.subscription
    }
    if (values.membership) {
      newLocations.membership = values.membership
    }
    if (values.badges) {
      newLocations.badges = values.badges
    }
    navigate(`/statistics?${querystring.stringify(newLocations)}`)
  }

  useEffect(() => {
    fetchSubscription()
    fetchBadges()
  }, [])
  console.log(values)
  const handleClearAll = () => {
    // alert(`Saved: ${selectedCountry}, ${selectedState || 'No state selected'}`)
    const newLocations = {
      country: 'United States',
    }
    setValues({
      country: 'United States',
      state: '',
      city: '',
      subscription: '',
      membership: '',
      badges: '',
    })
    navigate(`/statistics?${querystring.stringify(newLocations)}`)
  }
  return (
    <ContentBox>
      <Flex
        gap={3}
        // justifyContent="space-between"
        flexWrap="wrap"
      >
        <Flex gap={3}>
          <LocationFilter
            setValues={setValues}
            valueInit={values?.country}
            values={values}
          />
          <Select
            bg="white"
            placeholder="Select subscription"
            variant="filled"
            minWidth={200}
            maxWidth={210}
            borderRadius={8}
            value={values.subscription}
            onChange={e =>
              setValues((prev?: any) => ({
                ...prev,
                subscription: e.target.value,
              }))
            }
          >
            {optionsSubscription.length > 0 &&
              optionsSubscription.map((element?: any) => (
                <option key={element.id} value={element.id}>
                  {element?.name} - {element.tier_name && element.tier_name}
                </option>
              ))}
          </Select>
          <Select
            bg="white"
            placeholder="Select membership"
            variant="filled"
            minWidth={200}
            maxWidth={210}
            borderRadius={8}
            value={values.membership}
            onChange={e =>
              setValues((prev?: any) => ({
                ...prev,
                membership: e.target.value,
              }))
            }
          >
            {optionsMembership.length > 0 &&
              optionsMembership.map((element?: any) => (
                <option key={element} value={element}>
                  {element}
                </option>
              ))}
          </Select>
          <Select
            bg="white"
            placeholder="Select badge"
            variant="filled"
            minWidth={200}
            maxWidth={210}
            borderRadius={8}
            value={values.badges}
            onChange={e =>
              setValues((prev?: any) => ({ ...prev, badges: e.target.value }))
            }
          >
            {optionsBadges.length > 0 &&
              optionsBadges.map((element?: any) => (
                <option key={element.id} value={element.id}>
                  {element?.name}
                </option>
              ))}
          </Select>
        </Flex>
        <Flex gap={1} fontSize={14} alignItems="center">
          <Button
            bgColor="brand.400"
            color="white"
            borderRadius={32}
            onClick={handleSearch}
            height="fit-content"
            paddingY={2}
            fontSize={14}
          >
            Search
          </Button>
          <Button
            variant="ghost"
            onClick={handleClearAll}
            sx={{
              fontSize: 'inherit',
              fontWeight: 'light',
            }}
          >
            Clear all
          </Button>
        </Flex>
      </Flex>
    </ContentBox>
  )
}
export default Filters
